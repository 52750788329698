import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { graphql } from 'gatsby';
import { Layout, Link } from '@components';
import { Header2 } from '@styles';
import { Arrow } from '@styles/icons';
import { getKeyValue, compileRichText } from '@utils';
import { SMALL, MEDIUM, LIGHT } from '@utils/vars';
import {
  Title, ContentWrapper, NewsArticle, DateLabel,
} from './news-item-landing.styles';

const NewsArticleItem = (props) => {
  const {
    newsUrl, newsTitle, readMoreText, newsSummary, releaseDate, locale,
  } = props;
  const [trackingRef, isCardInView, entry] = useInView({ threshold: 0.1, triggerOnce: true });
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    if (entry) {
      setAnimate(isCardInView);
    }
  }, [isCardInView, entry]);

  return (
    <NewsArticle isCardInView={animate}>
      <Header2 textTheme="primaryHighlight" size={MEDIUM} ref={trackingRef}>
        {newsTitle}
      </Header2>
      {newsSummary && (
        compileRichText({ textTheme: 'primary', size: SMALL, locale })(newsSummary)
      )}
      <DateLabel>
        {releaseDate}
        <Link textTheme="primary" href={newsUrl} aria-label={`${newsTitle} ${readMoreText}`}>
          <Arrow strokeColor="primaryHighlight" height="29px" width="32px" />
        </Link>
      </DateLabel>
    </NewsArticle>
  );
};

const NewsItemLandingTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings, allNewsItems,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    title, readMoreText,
  } = contentEntry || {};
  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  return (
    <Layout
      location={location}
      navigation={navigation}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      navTheme={LIGHT}
      doNotIndex={doNotIndex}
    >
      <ContentWrapper>
        <Title textTheme="primary">{title}</Title>
        {allNewsItems && allNewsItems.edges
        && allNewsItems.edges.map((item) => {
          const {
            title: newsTitle, page: newsPage, summary: newsSummary, releaseDate,
          } = getKeyValue(item, 'node') || {};
          const { pageUrl: newsUrl } = (newsPage && newsPage[0]) || {};
          if (newsTitle) {
            return (
              <NewsArticleItem
                newsUrl={newsUrl}
                newsTitle={newsTitle}
                readMoreText={readMoreText}
                newsSummary={newsSummary}
                releaseDate={releaseDate}
                locale={locale}
              />
            );
          }
          return null;
        })}
      </ContentWrapper>
    </Layout>
  );
};

export default NewsItemLandingTemplate;

export const newsItemLandingPageQuery = graphql`
  query NewsItemLandingBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulNewsItemEndorsementsLanding {
          title
          intro {
            raw
          }
          readMoreText
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
    allNewsItems: allContentfulGeneralContent(sort: { fields: [releaseDate], order: DESC }, filter: {newsItem: {eq: true}, page: {elemMatch: {language: {eq: $locale}, dummyContent: {ne: true}}}, dummyContent: {ne: true}}) {
      edges {
        node {
          id
          title
          releaseDate(formatString: "MMMM Do, YYYY")
          summary {
            raw
          }
          page {
            pageUrl
          }
        }
      }
    }
  }
`;
