import styled, { css } from 'styled-components';
import { createBreakpoint, slideIn } from '@styles';

export const Title = styled.h1`
  font-size: 20px;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  color: ${({ theme }) => theme.palette.primary};
`;

export const ContentWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 80px;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 40px;
    margin-bottom: 120px;
  `)}
`;

export const NewsArticle = styled.article`
  padding: 40px 0px;
  border-top: 2px solid ${({ theme }) => theme.palette.lighterPrimary};
  position: relative;
  opacity: ${({ isCardInView }) => (isCardInView ? 1 : 0)};

  ${({ isCardInView }) => isCardInView && css`
    animation: ${slideIn};
    animation-duration: 0.5s;
    animation-timing-function: ease;
  `}

  h2 {
    transition: color 0.3s;
  }

  & a {
    svg {
      transition: transform 0.3s;
      polygon {
        transition: fill 0.3s;
      }
    }
    &:before {
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
    }
  }

  &:hover {
    h2 {
      color: ${({ theme }) => theme.palette.secondary};
    }
    a svg {
      transform: translateX(25%);
      polygon {
        fill: ${({ theme }) => theme.palette.secondary};
      }
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    padding: 60px 0px;
  `)}
`;

export const DateLabel = styled.p`
  color: ${({ theme }) => theme.palette.primary};
  margin-top: 32px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
